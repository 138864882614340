<template>
    <div class="block-users">
        <StackRouterHeaderBar
            :title="$translate('BLOCK_FRIEND')"
            :show-title="true"
            :class="{ 'bottom-border': showHeaderTitle }"
        />
        <div class="title f-16">
            <span class="spoqa-f-regular">아는 사람을 만날까봐 걱정마세요?</span><br />
            <span>차단한 회원과는 서로 소개되지 않습니다.</span>
        </div>
        <div class="menus">
            <div class="menu" v-for="menu in menus" :key="menu.id" @click="onClickMenu(menu.route)">
                <span class="f-medium c-black f-16" v-html="menu.name" />
                <img :src="require(`@/assets/images/icons/arrow_right.svg`)" width="16px" height="16px" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlockUserPage',
    data: () => ({
        showHeaderTitle: false,
    }),
    methods: {
        onClickMenu(routeName) {
            this.$stackRouter.push({
                name: routeName,
            })
        },
    },
    computed: {
        menus() {
            return [
                {
                    id: 1,
                    name: '연락처로 차단',
                    route: 'BlockContactsPage',
                },
                {
                    id: 2,
                    name: '직장명으로 차단',
                    route: 'BlockCompaniesPage',
                },
                {
                    id: 3,
                    name: '단과대/학과로 차단',
                    route: 'BlockCollegesPage',
                },
                {
                    id: 4,
                    name: '이름으로 차단',
                    route: 'BlockNamesPage',
                },
            ]
        },
    },
    created() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#FAF0E1',
                bottom: '#FFF9EF',
            },
        })
    },
}
</script>

<style scoped lang="scss">
.block-users {
    @include form-type-1;

    .menus {
        padding: 0 16px;
        .menu {
            padding: 16px;
            border-radius: 8px;
            border: 1px solid $grey-03;
            margin-bottom: 8px;

            @include between;
        }
    }
}
</style>
